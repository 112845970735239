<template>
    <div
        style="margin-top:400px;"
        class="pa-16 mx-auto"
    >
        <!-- # 레벨 -->
        <v-text-field
            class="rounded-lg shrink no-spinners centered-input"
            style="width:400px; min-width:400px;"
            label="레벨"
            dense
            hide-details
            v-model="level"
            outlined
            type="number"
            min="1"
            max="100"
        ></v-text-field>
    </div>
</template>
<script>
export default {
    data: () => ({
        level: 50
    })
}
</script>
<style scoped>
/* .centered-input >>> .v-label {
    left: 50% !important;
    transform: translateX(-50%);
    width:40px;
    text-align: center;
}
.centered-input >>> .v-label--active {
    transform: translateY(-18px) translateX(-50%);
}

.centered-input >>> legend {
    text-align: center;
} */
</style>