<template>
    <!-- # Defender -->
    <v-sheet
        :class="!$vuetify.breakpoint.mobile? 'rounded-12':''"
        :style="!$vuetify.breakpoint.mobile?
            {
                width: '362px',
                height: '780px',
                border: '1px solid #ddd',
                boxShadow: '0 0 6px 0 #e1e1e1'
            }
            : 
            {
                height: '100%'
            }
        "
    >
        <!-- # 상단 -->
        <v-sheet
            height="60"
            class="d-flex align-center rounded-t-12"
            style="border-bottom:1px solid #ddd;"
        >
            <!-- # 포켓몬 기본 정보 -->
            <div
                class="flex-grow-1 d-flex align-center"
            >
                <div
                    class="ml-3 mr-3 mb-2px"
                    style="width:36px; height:36px;"
                >
                    <v-img
                        :src="defender.sprite"
                    ></v-img>
                </div>
                <div>
                    <div
                        class="d-flex"
                        :style="defender.name && loading == false? 'cursor:pointer;':''"
                        @click="defender.name && loading == false? $emit('open_stat') : ''"
                    >
                        <p
                            class="mb-0 font-weight-medium alter_black--text mr-6px"
                            style="font-size:12px; letter-spacing:-1px;"
                        >
                            <span v-if="!defender.name && loading == false">선택값 없음</span>
                            {{defender.types[0]? defender.types[0] : (defender.name? '타입 없음' : '')}} <span v-if="defender.types[1]">/</span> {{defender.types[1]}}
                        </p>
                        <v-icon
                            v-if="defender.name && loading == false"
                            size="14"
                            color="primary lighten-2"
                        >
                            mdi-pencil
                        </v-icon>
                    </div>
                    <p
                        class="mb-0 font-weight-medium alter_grey--text"
                        style="margin-top:-1px; font-size:8px;"
                        :style="defender.name && loading == false? 'cursor:pointer;':''"
                        @click="defender.name && loading == false? $emit('open_stat') : ''"
                    >
                        H{{defender.stats.hp}} A{{defender.stats.attack}} B{{defender.stats.defense}} C{{defender.stats.special_attack}} D{{defender.stats.special_defense}} S{{defender.stats.speed}}
                    </p>
                    <p
                        class="mb-0 font-weight-regular alter_grey--text"
                        style="margin-top:-1px; font-size:8px; border-bottom:1px solid #8FC31F;"
                    >
                        시즌 00 싱글 00위 더블 00위
                    </p>
                </div>
            </div>

            <v-spacer></v-spacer>

            <!-- # 교체 -->
            <div
                v-if="$vuetify.breakpoint.mobile"
                style="width:30px; height:16px; padding-top:1px; cursor:pointer;"
                @click="$emit('switch')"
            >
                <v-img
                    src="@/assets/switch.svg"
                ></v-img>
            </div>

            <!-- # 계산 버튼 -->
            <v-btn
                v-if="$vuetify.breakpoint.mobile"
                depressed
                small
                width="64"
                height="26"
                class="rounded-lg ml-2"
                style="background:#A1A4BA; font-size:14px; font-weight:300 !important;"
                dark
                @click="$emit('open_caculate')"
            >
                계산
            </v-btn>

            <!-- # 사용자용 -->
            <div
                v-if="!$store.state.is_admin"
            >
                <!-- 방패 -->
                <div
                    class="ml-2 mr-4"
                    style="width:24px; height:24px;"
                    @click="$vuetify.breakpoint.mobile? $store.commit('update_mobile_tab', 'attacker'):''"
                >
                    <v-img
                        src="@/assets/defender.svg"
                    ></v-img>
                </div>
            </div>

            <!-- # 작업용 (스피드) -->
            <div
                v-if="$store.state.is_admin"
                class="d-flex"
            >
                <!-- 방패 -->
                <div
                    class="ml-2 mr-3"
                    style="width:24px; height:24px;"
                    @click="$vuetify.breakpoint.mobile? $store.commit('update_mobile_tab', 'attacker'):''"
                >
                    <v-img
                        src="@/assets/defender.svg"
                    ></v-img>
                </div>

                <!-- 스피드 -->
                <div
                    class="mr-4 mt-2px"
                    style="width:14px; height:24px;"
                    @click="$vuetify.breakpoint.mobile? $store.commit('update_mobile_tab', 'speed_defender') : $store.commit('update_is_speed', true)"
                >
                    <v-img
                        src="@/assets/speed_off.svg"
                    ></v-img>
                </div>
            </div>
        </v-sheet>

        <!-- # 1단 -->
        <v-sheet
            height="80"
            style="border-bottom:1px solid #ddd; padding:10px;"
        >
            <!-- # 1줄 -->
            <div
                class="d-flex align-center"
                style="height:64px;"
            >
                <!-- # 포켓몬 이름 -->
                <v-autocomplete
                    class="rounded-lg shrink"
                    style="width:141px; min-width:141px;"
                    label="포켓몬 이름"
                    placeholder="포켓몬 선택"
                    persistent-placeholder
                    dense
                    hide-details
                    item-value="en"
                    item-text="kr"
                    :items="pokemon_list"
                    v-model="defender.name"
                    outlined
                    :menu-props="{
                        offsetY: true,
                        maxHeight: $vuetify.breakpoint.mobile ? 216 : 316
                    }"
                    @change="loadDefender()"
                ></v-autocomplete>

                <!-- # 타입 -->
                <v-select
                    class="ml-1 rounded-lg"
                    label="타입"
                    placeholder="타입 선택"
                    persistent-placeholder
                    dense
                    hide-details
                    :items="type_list"
                    v-model="defender.types"
                    outlined
                    multiple
                    chips
                    deletable-chips
                    :menu-props="{
                        offsetY: true,
                        maxHeight: $vuetify.breakpoint.mobile ? 216 : 316
                    }"
                    @change="defender_types_updated()"
                >
                    <template v-slot:selection="{ item, index }">
                        <v-chip
                            small
                            class="mx-1 px-2 pr-3"
                            color="#eee"
                            close
                            @click:close="removeType(index)"
                        >
                            <span>{{ item }}</span>
                        </v-chip>
                    </template>
                </v-select>
            </div>
        </v-sheet>

        <!-- # 2단 -->
        <v-sheet
            height="324"
            style="border-bottom:1px solid #ddd; padding:10px;"
        >
            <!-- # 1줄 -->
            <div
                class="d-flex align-center"
                style="height:28px;"
            >
                <p
                    class="mb-0"
                    style="font-size:14px; font-weight: 500; letter-spacing:-1px;"
                >
                    체력 수치
                </p>
            </div>

            <!-- # 2줄 -->
            <div
                class="d-flex align-center mt-1"
                style="height:58px;"
            >
                <!-- # 노력치 -->
                <div
                    class="d-flex align-center"
                >
                    <!-- # 입력 -->
                    <v-text-field
                        class="rounded-lg shrink"
                        style="width:50px;"
                        label="노력치"
                        dense
                        hide-details
                        v-model="defender.effort_for_hp"
                        outlined
                        placeholder="0"
                        :persistent-placeholder="true"
                    ></v-text-field>

                    <!-- # MAX & MIN -->
                    <div
                        class="ml-5px"
                        style="height:52px;"
                    >
                        <div>
                            <v-btn
                                class="rounded-lg mb-2px"
                                depressed
                                :color="defender.effort_for_hp == 252? '#E4007F':'#E9C46A'"
                                dark
                                small
                                height="23"
                                width="54"
                                style="font-size:14px; font-weight:300 !important;"
                                @click="defender.effort_for_hp = 252"
                            >
                                MAX
                            </v-btn>
                        </div>
                        <div>
                            <v-btn
                                class="rounded-lg"
                                depressed
                                :color="defender.effort_for_hp == 0? '#E4007F':'#E9C46A'"
                                dark
                                small
                                height="23"
                                width="54"
                                style="font-size:14px; font-weight:300 !important;"
                                @click="defender.effort_for_hp = 0"
                            >
                                MIN
                            </v-btn>
                        </div>
                    </div>

                    <!-- # + & - -->
                    <div
                        class="ml-1"
                        style="height:52px;"
                    >
                        <div>
                            <v-btn
                                class="rounded-lg mb-2px"
                                depressed
                                color="#E9C46A"
                                dark
                                x-small
                                height="23"
                                width="24"
                                min-width="0"
                                style="font-size:16px; font-weight:300 !important;"
                                @click="defender.effort_for_hp < 252? defender.effort_for_hp++ : ''"
                            >
                                ＋
                            </v-btn>
                        </div>
                        <div>
                            <v-btn
                                class="rounded-lg"
                                depressed
                                color="#E9C46A"
                                dark
                                x-small
                                height="23"
                                width="24"
                                min-width="0"
                                style="font-size:16px; font-weight:300 !important;"
                                @click="defender.effort_for_hp > 0? defender.effort_for_hp-- : ''"
                            >
                                －
                            </v-btn>
                        </div>
                    </div>
                </div>

                <!-- # 개체값 -->
                <div
                    class="ml-2 d-flex align-center"
                >
                    <!-- # 입력 -->
                    <v-text-field
                        class="rounded-lg shrink"
                        style="width:50px;"
                        label="개체값"
                        dense
                        hide-details
                        v-model="defender.individual_value_for_hp"
                        outlined
                        placeholder="0"
                        :persistent-placeholder="true"
                    ></v-text-field>

                    <!-- # MAX & MIN -->
                    <div
                        class="ml-5px"
                        style="height:52px;"
                    >
                        <div>
                            <v-btn
                                class="rounded-lg mb-2px"
                                depressed
                                :color="defender.individual_value_for_hp == 31? '#E4007F':'#E9C46A'"
                                dark
                                small
                                height="23"
                                width="54"
                                style="font-size:14px; font-weight:300 !important;"
                                @click="defender.individual_value_for_hp = 31"
                            >
                                MAX
                            </v-btn>
                        </div>
                        <div>
                            <v-btn
                                class="rounded-lg"
                                depressed
                                :color="defender.individual_value_for_hp == 0? '#E4007F':'#E9C46A'"
                                dark
                                small
                                height="23"
                                width="54"
                                style="font-size:14px; font-weight:300 !important;"
                                @click="defender.individual_value_for_hp = 0"
                            >
                                MIN
                            </v-btn>
                        </div>
                    </div>

                    <!-- # + & - -->
                    <div
                        class="ml-1"
                        style="height:52px;"
                    >
                        <div>
                            <v-btn
                                class="rounded-lg mb-2px"
                                depressed
                                color="#E9C46A"
                                dark
                                x-small
                                height="23"
                                width="24"
                                min-width="0"
                                style="font-size:16px; font-weight:300 !important;"
                                @click="defender.individual_value_for_hp < 31? defender.individual_value_for_hp++ : ''"
                            >
                                ＋
                            </v-btn>
                        </div>
                        <div>
                            <v-btn
                                class="rounded-lg"
                                depressed
                                color="#E9C46A"
                                dark
                                x-small
                                height="23"
                                width="24"
                                min-width="0"
                                style="font-size:16px; font-weight:300 !important;"
                                @click="defender.individual_value_for_hp > 0? defender.individual_value_for_hp-- : ''"
                            >
                                －
                            </v-btn>
                        </div>
                    </div>
                </div>

                <!-- # 실수값 -->
                <div
                    class="ml-2 d-flex align-center"
                >
                    <!-- # 입력 -->
                    <v-text-field
                        class="rounded-lg shrink"
                        style="width:50px;"
                        label="실수값"
                        dense
                        hide-details
                        v-model="hp_modifier"
                        outlined
                        readonly
                    ></v-text-field>
                </div>
            </div>

            <!-- # 3줄 -->
            <div
                class="d-flex align-center"
                style="height:28px;"
            >
                <p
                    class="mb-0"
                    style="font-size:14px; font-weight: 500; letter-spacing:-1px;"
                >
                    방어 수치
                </p>
                <p
                    class="mt-2 mb-0 ml-2"
                    style="font-size:8px;"
                >
                    *해당값은 기술타입에 따라 방어 / 특수방어로 적용됩니다.
                </p>
            </div>

            <!-- # 4줄 -->
            <div
                class="d-flex align-center mt-1 center_label"
                style="height:58px;"
            >
                <!-- # 노력치 -->
                <div
                    class="d-flex align-center"
                >
                    <!-- # 입력 -->
                    <v-text-field
                        class="rounded-lg shrink"
                        style="width:50px;"
                        label="노력치"
                        dense
                        hide-details
                        v-model="defender.effort_for_defend"
                        outlined
                        placeholder="0"
                        :persistent-placeholder="true"
                    ></v-text-field>

                    <!-- # MAX & MIN -->
                    <div
                        class="ml-5px"
                        style="height:52px;"
                    >
                        <div>
                            <v-btn
                                class="rounded-lg mb-2px"
                                depressed
                                :color="defender.effort_for_defend == 252? '#E4007F':'#E9C46A'"
                                dark
                                small
                                height="23"
                                width="54"
                                style="font-size:14px; font-weight:300 !important;"
                                @click="defender.effort_for_defend = 252"
                            >
                                MAX
                            </v-btn>
                        </div>
                        <div>
                            <v-btn
                                class="rounded-lg"
                                depressed
                                :color="defender.effort_for_defend == 0? '#E4007F':'#E9C46A'"
                                dark
                                small
                                height="23"
                                width="54"
                                style="font-size:14px; font-weight:300 !important;"
                                @click="defender.effort_for_defend = 0"
                            >
                                MIN
                            </v-btn>
                        </div>
                    </div>

                    <!-- # + & - -->
                    <div
                        class="ml-1"
                        style="height:52px;"
                    >
                        <div>
                            <v-btn
                                class="rounded-lg mb-2px"
                                depressed
                                color="#E9C46A"
                                dark
                                x-small
                                height="23"
                                width="24"
                                min-width="0"
                                style="font-size:16px; font-weight:300 !important;"
                                @click="defender.effort_for_defend < 252? defender.effort_for_defend++ : ''"
                            >
                                ＋
                            </v-btn>
                        </div>
                        <div>
                            <v-btn
                                class="rounded-lg"
                                depressed
                                color="#E9C46A"
                                dark
                                x-small
                                height="23"
                                width="24"
                                min-width="0"
                                style="font-size:16px; font-weight:300 !important;"
                                @click="defender.effort_for_defend > 0? defender.effort_for_defend-- : ''"
                            >
                                －
                            </v-btn>
                        </div>
                    </div>
                </div>

                <!-- # 개체값 -->
                <div
                    class="ml-2 d-flex align-center"
                >
                    <!-- # 입력 -->
                    <v-text-field
                        class="rounded-lg shrink"
                        style="width:50px;"
                        label="개체값"
                        dense
                        hide-details
                        v-model="defender.individual_value_for_defend"
                        outlined
                        placeholder="0"
                        :persistent-placeholder="true"
                    ></v-text-field>

                    <!-- # MAX & MIN -->
                    <div
                        class="ml-5px"
                        style="height:52px;"
                    >
                        <div>
                            <v-btn
                                class="rounded-lg mb-2px"
                                depressed
                                :color="defender.individual_value_for_defend == 31? '#E4007F':'#E9C46A'"
                                dark
                                small
                                height="23"
                                width="54"
                                style="font-size:14px; font-weight:300 !important;"
                                @click="defender.individual_value_for_defend = 31"
                            >
                                MAX
                            </v-btn>
                        </div>
                        <div>
                            <v-btn
                                class="rounded-lg"
                                depressed
                                :color="defender.individual_value_for_defend == 0? '#E4007F':'#E9C46A'"
                                dark
                                small
                                height="23"
                                width="54"
                                style="font-size:14px; font-weight:300 !important;"
                                @click="defender.individual_value_for_defend = 0"
                            >
                                MIN
                            </v-btn>
                        </div>
                    </div>

                    <!-- # + & - -->
                    <div
                        class="ml-1"
                        style="height:52px;"
                    >
                        <div>
                            <v-btn
                                class="rounded-lg mb-2px"
                                depressed
                                color="#E9C46A"
                                dark
                                x-small
                                height="23"
                                width="24"
                                min-width="0"
                                style="font-size:16px; font-weight:300 !important;"
                                @click="defender.individual_value_for_defend < 31? defender.individual_value_for_defend++ : ''"
                            >
                                ＋
                            </v-btn>
                        </div>
                        <div>
                            <v-btn
                                class="rounded-lg"
                                depressed
                                color="#E9C46A"
                                dark
                                x-small
                                height="23"
                                width="24"
                                min-width="0"
                                style="font-size:16px; font-weight:300 !important;"
                                @click="defender.individual_value_for_defend > 0? defender.individual_value_for_defend-- : ''"
                            >
                                －
                            </v-btn>
                        </div>
                    </div>
                </div>

                <!-- # 실수값 -->
                <div
                    class="ml-2 d-flex align-center"
                >
                    <!-- # 입력 -->
                    <v-text-field
                        class="rounded-lg shrink"
                        style="width:50px;"
                        label="실수값"
                        dense
                        hide-details
                        v-model="defend_modifier"
                        outlined
                        readonly
                    ></v-text-field>
                </div>
            </div>

            <!-- # 5줄 -->
            <div
                class="d-flex align-center mt-1 center_label"
                style="height:58px;"
            >
                <!-- # 랭크업 -->
                <div
                    class="d-flex align-center"
                >
                    <!-- # 입력 -->
                    <v-text-field
                        class="rounded-lg shrink"
                        style="width:50px;"
                        label="랭크업"
                        dense
                        hide-details
                        v-model="defender.rankup"
                        outlined
                        placeholder="0"
                        :persistent-placeholder="true"
                    ></v-text-field>

                    <!-- # MAX & MIN -->
                    <div
                        class="ml-5px"
                        style="height:52px;"
                    >
                        <div>
                            <v-btn
                                class="rounded-lg mb-2px"
                                depressed
                                :color="defender.rankup == 6? '#E4007F':'#E9C46A'"
                                dark
                                small
                                height="23"
                                width="54"
                                style="font-size:14px; font-weight:300 !important;"
                                @click="defender.rankup = 6"
                            >
                                MAX
                            </v-btn>
                        </div>
                        <div>
                            <v-btn
                                class="rounded-lg"
                                depressed
                                :color="defender.rankup == -6? '#E4007F':'#E9C46A'"
                                dark
                                small
                                height="23"
                                width="54"
                                style="font-size:14px; font-weight:300 !important;"
                                @click="defender.rankup = -6"
                            >
                                MIN
                            </v-btn>
                        </div>
                    </div>

                    <!-- # + & - -->
                    <div
                        class="ml-1"
                        style="height:52px;"
                    >
                        <div>
                            <v-btn
                                class="rounded-lg mb-2px"
                                depressed
                                color="#E9C46A"
                                dark
                                x-small
                                height="23"
                                width="24"
                                min-width="0"
                                style="font-size:16px; font-weight:300 !important;"
                                @click="defender.rankup < 6? defender.rankup++ : ''"
                            >
                                ＋
                            </v-btn>
                        </div>
                        <div>
                            <v-btn
                                class="rounded-lg"
                                depressed
                                color="#E9C46A"
                                dark
                                x-small
                                height="23"
                                width="24"
                                min-width="0"
                                style="font-size:16px; font-weight:300 !important;"
                                @click="defender.rankup > -6? defender.rankup-- : ''"
                            >
                                －
                            </v-btn>
                        </div>
                    </div>
                </div>

                <!-- # 방어력 -> 레벨 & 내구력 -->
                <div
                    class="ml-2 d-flex align-center"
                >
                    <!-- # 방어력 -->
                    <!-- <v-text-field
                        class="rounded-lg shrink no-spinners center-input"
                        style="width:50px; min-width:50px;"
                        label="방어력"
                        dense
                        hide-details
                        v-model="defend"
                        outlined
                        type="number"
                        readonly
                    ></v-text-field> -->

                    <!-- # 레벨 -->
                    <v-text-field
                        class="rounded-lg shrink no-spinners"
                        style="width:50px; min-width:50px;"
                        label="레벨"
                        dense
                        hide-details
                        v-model="defender.level"
                        outlined
                        placeholder="0"
                        type="number"
                        :persistent-placeholder="true"
                    ></v-text-field>

                    <div
                        class="ml-5px flex-1"
                    >
                        <!-- # 내구력 -->
                        <v-text-field
                            class="rounded-lg"
                            label="내구력"
                            dense
                            hide-details
                            v-model="durability"
                            outlined
                            placeholder="0"
                            :persistent-placeholder="true"
                        ></v-text-field>
                    </div>
                </div>
            </div>

            <!-- # 6줄 -->
            <div
                class="d-flex align-center mt-1"
                style="height:58px;"
            >
                <!-- # 성격 보정 -->
                <div
                    class="d-flex align-center center_label"
                    style="position:relative;"
                >
                    <!-- # 입력 -->
                    <!-- <v-select
                        class="rounded-lg shrink no-spinners"
                        style="width:195px;"
                        label="성격 보정"
                        dense
                        hide-details
                        :items="[0.9, 1.0, 1.1]"
                        v-model="defender.personality"
                        outlined
                        type="number"
                    ></v-select> -->

                    <!-- # 입력 -->
                    <v-text-field
                        class="rounded-lg shrink no-spinners"
                        style="width:195px;"
                        label="성격 보정"
                        placeholder=""
                        persistent-placeholder
                        dense
                        hide-details
                        outlined
                    ></v-text-field>

                    <div
                        style="position:absolute; width:160px; left:50%; transform:translateX(-50%);"
                        class="text_button d-flex justify-space-between align-center"
                    >
                        <v-btn
                            min-width="40"
                            width="40"
                            text
                            small
                            v-ripple="false"
                            style="background:none;"
                            class="font-weight-regular px-0"
                            :color="defender.personality == 0.9? '#E4007F':''"
                            @click="defender.personality = 0.9"
                        >
                            <span style="font-size:8px;">x</span>0.9
                        </v-btn>

                        <div style="width:1px; height:14px; background:#969696;"></div>

                        <v-btn
                            min-width="40"
                            width="40"
                            text
                            small
                            v-ripple="false"
                            style="background:none;"
                            class="font-weight-regular px-0"
                            :color="defender.personality == 1.0? '#E4007F':''"
                            @click="defender.personality = 1.0"
                        >
                            <span style="font-size:8px;">x</span>1.0
                        </v-btn>

                        <div style="width:1px; height:14px; background:#969696;"></div>

                        <v-btn
                            min-width="40"
                            width="40"
                            text
                            small
                            v-ripple="false"
                            style="background:none;"
                            class="font-weight-regular px-0"
                            :color="defender.personality == 1.1? '#E4007F':''"
                            @click="defender.personality = 1.1"
                        >
                            <span style="font-size:8px;">x</span>1.1
                        </v-btn>
                    </div>
                </div>

                <!-- # 배수 -->
                <div
                    class="ml-5px d-flex align-center center_label"
                >
                    <!-- # 배수 -->
                    <v-text-field
                        class="rounded-lg shrink no-spinners"
                        style="width:60px; min-width:60px;"
                        label="배수"
                        dense
                        hide-details
                        v-model="defender.multiple"
                        outlined
                        type="number"
                        :min="0.1"
                        :max="10"
                        step="0.01"
                        :precision="2"
                        @change="restrictDecimal"
                        :key="componentKey"
                    ></v-text-field>
                </div>
            </div>
        </v-sheet>

        <!-- # 3단 -->
        <v-sheet
            style="padding:10px;"
        >
            <!-- # 1줄 -->
            <div
                class="d-flex align-center"
                style="height:64px;"
            >
                <!-- # 특성 -->
                <v-autocomplete
                    class="rounded-lg shrink"
                    style="width:195px; min-width:195px;"
                    label="특성"
                    placeholder="특성 선택"
                    persistent-placeholder
                    :menu-props="{
                        offsetY: true,
                        maxHeight: $vuetify.breakpoint.mobile ? 152 : 216
                    }"
                    dense
                    hide-details
                    :items="abilities_list"
                    v-model="defender.ability"
                    outlined
                    clearable
                ></v-autocomplete>
            </div>

            <!-- # 2줄 -->
            <div
                class="d-flex align-center"
                style="height:64px;"
            >
                <!-- # 도구 -->
                <v-autocomplete
                    class="rounded-lg shrink"
                    style="width:195px; min-width:195px;"
                    :menu-props="{
                        offsetY: true,
                        maxHeight: $vuetify.breakpoint.mobile ? 152 : 216
                    }"
                    label="도구"
                    placeholder="도구 선택"
                    persistent-placeholder
                    dense
                    hide-details
                    :items="equipment_list"
                    v-model="defender.equipment"
                    outlined
                    clearable
                ></v-autocomplete>
            </div>

            <!-- # 3줄 -->
            <div>
                <div
                    class="d-flex align-center"
                >
                    <v-checkbox
                        class="flex-grow-1"
                        style="width:33%;"
                        label="리플렉터"
                        dense
                        hide-details
                        v-model="defender.support.reflect"
                    ></v-checkbox>
                    <v-checkbox
                        class="flex-grow-1"
                        style="width:33%;"
                        label="빛의장막"
                        dense
                        hide-details
                        v-model="defender.support.light_screen"
                    ></v-checkbox>
                    <v-checkbox
                        class="flex-grow-1"
                        style="width:33%;"
                        label="오로라베일"
                        dense
                        hide-details
                        v-model="defender.support.aurora_veil"
                    ></v-checkbox>
                </div>
            </div>

            <p
                v-if="attacker.name && attacker.move.name && defender.name"
                style="font-weight:600; font-size:16px;"
                class="mb-0 mt-6"
            >
                {{$store.state.final_85}} ~ {{$store.state.final_100}} ({{(($store.state.final_85 / hp_modifier).toFixed(3) * 100).toFixed(1)}}% ~ {{(($store.state.final_100 / hp_modifier).toFixed(3) * 100).toFixed(1)}}%)
            </p>

            <p
                v-if="attacker.name && attacker.move.name && defender.name"
                style="font-weight:500; font-size:10px; color:#666; letter-spacing:-0.5px;"
                class="mb-2"
            >
                (
                    {{$store.state.final_85}},
                    {{$store.state.final_86}},
                    {{$store.state.final_87}},
                    {{$store.state.final_88}},
                    {{$store.state.final_89}},
                    {{$store.state.final_90}},
                    {{$store.state.final_91}},
                    {{$store.state.final_92}},
                    {{$store.state.final_93}},
                    {{$store.state.final_94}},
                    {{$store.state.final_95}},
                    {{$store.state.final_96}},
                    {{$store.state.final_97}},
                    {{$store.state.final_98}},
                    {{$store.state.final_99}},
                    {{$store.state.final_100}}
                )
            </p>

            <p
                v-if="attacker.name && attacker.move.name && defender.name"
                style="font-weight:600; font-size:16px;"
                class="mb-0"
            >
                {{$store.state.final_final}}
            </p>
        </v-sheet>
    </v-sheet>
</template>
<script>
import common from '@/data/common.json'
import defender from '@/data/defender.json'
import condition from '@/data/condition.json'

export default {
    props: [
        "attacker",
        "damage_class",
        "reset_key"
    ],

    data: () => ({
        // 펀치기술
        punch_list: condition.punch_list,

        // 반동데미지 기술
        rebound_list: condition.rebound_list,

        // 접촉기술 > 물리 기술이지만 접촉기술이 아닌 경우
        touch_but_no_physical_list: condition.touch_but_no_physical_list,

        // 접촉기술 > 특수기술이지만 접촉 기술인 경우
        touch_but_special_list: condition.touch_but_special_list,

        // 베기 기술
        cut_list: condition.cut_list,

        // 무는 기술
        bite_list: condition.bite_list,

        // 소리 기술
        sound_list: condition.sound_list,

        // 파동 기술
        wave_list: condition.wave_list,

        // 바람 기술
        wind_list: condition.wind_list,

        // 구슬/폭탄 기술
        bomb_list: condition.bomb_list,

        // 교체 기술
        switch_list: condition.switch_list,

        // 자폭 기술
        suicide_list: condition.suicide_list,

        // 선공 기술
        strike_first_list: condition.strike_first_list,

        // 타입 목록
        type_list: common.type_list,

        // 포켓몬 목록
        pokemon_list: [],

        // Defender > 특성
        abilities_list: defender.abilities_list,

        // Defender > 도구
        equipment_list: defender.equipment_list,

        // # Defender
        defender: {
            // 이름
            name: "",

            // 이미지
            sprite: null,

            // 타입
            types: [],

            // 스탯
            stats: {
                // H
                hp: 0,

                // A
                attack: 0,

                // B
                defense: 0,

                // C
                special_attack: 0,

                // D
                special_defense: 0,

                // S
                speed: 0,
            },

            // 노력치
            effort_for_hp: 0,
            effort_for_defend: 0,

            // 개체값
            individual_value_for_hp: 31,
            individual_value_for_defend: 31,

            // 랭크업
            rankup: 0,

            // 성격 보정
            personality: 1.0,

            // 레벨
            level: 50,

            // 배수
            multiple: 1,

            // 특성
            ability: "",

            // 도구
            equipment: "",

            // 서포트
            support: {
                // 리플렉터
                reflect: false,

                // 빛의장막 
                light_screen: false,

                // 오로라베일
                aurora_veil: false,
            }
        },

        loading: false,
        componentKey: 0,
    }),

    // 리플렉터, 빛의장막, 오로라베일 동시선택 불가(조금 다름) 처리
    watch: {
        reset_key() {
            this.defender = {
                name: this.defender.name,
                sprite: this.defender.sprite,
                types: this.defender.types,
                stats: this.defender.stats,
                effort_for_hp: 0,
                effort_for_defend: 0,
                individual_value_for_hp: 31,
                individual_value_for_defend: 31,
                rankup: 0,
                personality: 1.0,
                level: 50,
                multiple: 1,
                ability: "",
                equipment: "",
                support: {
                    reflect: false,
                    light_screen: false,
                    aurora_veil: false,
                }
            }
        },

        "defender.support.reflect"(newVal) {
            if (newVal) {
                this.defender.support.aurora_veil = false
            }
        },
        "defender.support.light_screen"(newVal) {
            if (newVal) {
                this.defender.support.aurora_veil = false
            }
        },
        "defender.support.aurora_veil"(newVal) {
            if (newVal) {
                this.defender.support.reflect = false
                this.defender.support.light_screen = false
            } 
        },

        defender: {
            handler(newValue, oldValue) {
                this.defender.stats.defense = parseInt(this.defender.stats.defense)
                this.defender.stats.special_defense = parseInt(this.defender.stats.special_defense)

                this.$emit('defender_updated', newValue)
            },
            deep: true
        }
    },

    computed: {
        // 체력 실수값
        hp_modifier(){
            // (체력 종족값(H) + 개체값/2 + 노력치/8 + 10) + 레벨
            // return Math.floor(
            //     (this.defender.stats.hp + this.defender.individual_value_for_hp / 2 + this.defender.effort_for_hp / 8 + 10) 
            //     + parseInt(this.defender.level)
            // )

            // (((종족값x2)+개체값+(노력치/4)+100)x레벨/100) + 10
            return Math.floor(
                (
                    this.defender.stats.hp * 2
                    + this.defender.individual_value_for_hp
                    + this.defender.effort_for_hp / 4 + 100
                ) 
                * parseInt(this.defender.level) / 100
                + 10
            )
        },

        // 방어력
        defend(){
            return this.damage_class == "physical"? this.defender.stats.defense : this.defender.stats.special_defense
        },

        // 방어 실수값
        defend_modifier(){
            // (방어 종족값(B or D) + 개체값/2 + 노력치/8 + 5) * 성격보정
            // return Math.floor(
            //     (
            //         this.defend
            //         + this.defender.individual_value_for_defend / 2
            //         + this.defender.effort_for_defend / 8
            //         + 5
            //     ) 
            //     * this.defender.personality
            // )

            // [{(종족값x2)+개체값+(노력치/4)}x레벨/100+5]x성격보정
            return Math.floor(
                (
                    (
                        this.defend * 2
                        + this.defender.individual_value_for_defend
                        + this.defender.effort_for_defend / 4
                    ) 
                    * parseInt(this.defender.level) / 100
                    + 5
                )
                * this.defender.personality
            )
        },

        // 랭크업에 따른 보정값
        rankup_value(){
            if(this.defender.rankup == -6) return 0.25
            else if(this.defender.rankup == -5) return 0.29
            else if(this.defender.rankup == -4) return 0.33
            else if(this.defender.rankup == -3) return 0.4
            else if(this.defender.rankup == -2) return 0.5
            else if(this.defender.rankup == -1) return 0.66
            else if(this.defender.rankup == 0) return 1
            else if(this.defender.rankup == 1) return 1.5
            else if(this.defender.rankup == 2) return 2
            else if(this.defender.rankup == 3) return 2.5
            else if(this.defender.rankup == 4) return 3
            else if(this.defender.rankup == 5) return 3.5
            else if(this.defender.rankup == 6) return 4
        },

        // 방어력 (방어 실수값 X 각종 보정)
        caculated_defend(){
            let defend = 1

            // # 방어 실수값
            defend = this.defend_modifier

            // # 랭크보정
            defend = defend * this.rankup_value

            // # 특성보정
            if(this.defender.ability == "이상한비늘" && this.attacker && this.attacker.cc && this.damage_class == "physical"){
                defend = defend * 0.75
            }
            else if(this.defender.ability == "플라워기프트" && this.attacker && this.attacker.cc && this.damage_class == "special"){
                defend = defend * 1.5
            }

            // # 상대특성보정
            if(this.attacker && this.attacker.ability == "재앙의검" && this.damage_class == "physical"){
                defend = defend * 0.75
            }
            else if(this.attacker && this.attacker.ability == "재앙의구슬" && this.damage_class == "special"){
                defend = defend * 0.75
            }

            // # 도구보정
            if(this.defender.equipment == "심해의비늘" && this.defender.name == "진주몽" && this.damage_class == "special"){
                defend = defend * 2
            }
            else if(this.defender.equipment == "금속파우더" && this.defender.name == "메타몽"){
                defend = defend * 2
            }
            else if(this.defender.equipment == "진화의휘석"){
                defend = defend * 1.5
            }
            else if(this.defender.equipment == "돌격조끼" && this.damage_class == "special"){
                defend = defend * 1.5
            }

            // # 날씨
            if(this.attacker && this.attacker.weather == "모래바람" && this.damage_class == "special"){
                defend = defend * 1.5
            }
            else if(this.attacker && this.attacker.weather == "설경" && this.damage_class == "physical"){
                defend = defend * 1.5
            }

            // # 서포트
            if(this.attacker && this.attacker.support && this.attacker.support.disaster_sword && this.damage_class == "physical"){
                defend = defend * 0.75
            }
            else if(this.attacker && this.attacker.support && this.attacker.support.disaster_orb && this.damage_class == "special"){
                defend = defend * 0.75
            }
            else if(this.attacker && this.attacker.support && this.attacker.support.flower_gift && this.attacker.weather == "쾌청" && this.damage_class == "special"){
                defend = defend * 1.5
            }

            return defend
        },

        // # Defender_add
        defender_extra(){
            let extra = 1

            if(this.defender.ability == "두꺼운지방" && (this.attacker && this.attacker && this.attacker.move.type == "불꽃" || this.attacker && this.attacker && this.attacker.move.type == "얼음")){
                extra = extra * 2
            }
            else if(this.defender.ability == "내열" && this.attacker && this.attacker && this.attacker.move.type == "불꽃"){
                extra = extra * 2
            }
            else if(this.defender.ability == "수포" && this.attacker && this.attacker && this.attacker.move.type == "불꽃"){
                extra = extra * 2
            }
            else if(this.defender.ability == "건조피부" && this.attacker && this.attacker && this.attacker.move.type == "불꽃"){
                extra = extra * 0.8
            }
            else if(this.defender.ability == "멀티스케일"){
                extra = extra * 2
            }
            else if(this.defender.ability == "스펙터가드"){
                extra = extra * 2
            }
            else if(this.defender.ability == "테라셸"){
                extra = extra * 2
            }
            else if(this.defender.ability == "복슬복슬"){
                // 접촉기술
                if(
                    (this.attacker && this.attacker && this.attacker.move.damage_class == "physical" && !this.touch_but_no_physical_list.includes(this.translateMove(this.attacker && this.attacker && this.attacker.move.name)))
                    || this.touch_but_special_list.includes(this.translateMove(this.attacker && this.attacker && this.attacker.move.name))
                ){
                    extra = extra * 2
                }
                else if(
                    this.attacker && this.attacker && this.attacker.move.type == "불꽃"
                ){
                    extra = extra * 0.5
                }
            }
            else if(this.defender.ability == "정화의소금" && this.attacker && this.attacker && this.attacker.move.type == "고스트"){
                extra = extra * 2
            }
            else if(this.defender.ability == "펑크록" && this.sound_list.includes(this.translateMove(this.attacker && this.attacker && this.attacker.move.name))){
                extra = extra * 2
            }
            else if(this.defender.ability == "퍼코트" && this.attacker && this.attacker && this.attacker.move.damage_class == "physical"){
                extra = extra * 2
            }
            else if(this.defender.ability == "얼음인분" && this.attacker && this.attacker && this.attacker.move.damage_class == "special"){
                extra = extra * 2
            }

            return extra
        },

        // 내구력
        durability(){
            return Math.floor(
                this.hp_modifier
                * this.caculated_defend
                * this.defender_extra
                * this.defender.multiple
                / 0.411
                // * this.mod1 (리플렉터, 빛의장막, 오로라베일은 다시 제외)
                * this.mod3
            )
        },

        // # Mod1
        mod1(){
            let mod1 = 1

            // # 리플렉터
            if(this.defender && this.defender.support.reflect && !this.defender.support.aurora_veil && this.damage_class == "physical"){
                mod1 = mod1 * 2
            }

            // # 빛의장막
            if(this.defender && this.defender.support.light_screen && !this.defender.support.aurora_veil && this.damage_class == "special"){
                mod1 = mod1 * 2
            }

            // # 오로라베일
            if(this.defender && this.defender.support.aurora_veil && !this.defender.support.reflect && !this.defender.support.light_screen){
                mod1 = mod1 * 2
            }

            return mod1
        },

        // # Mod3
        mod3(){
            let mod3 = 1

            // # 상대특성보정
            if(this.defender && this.defender.ability == "하드록" && ((this.type_modifier1 * this.type_modifier2) >= 2)){
                mod3 = mod3 * 1.5
            }
            else if(this.defender && this.defender.ability == "필터" && ((this.type_modifier1 * this.type_modifier2) >= 2)){
                mod3 = mod3 * 1.5
            }
            else if(this.defender && this.defender.ability == "프리즘아머" && ((this.type_modifier1 * this.type_modifier2) >= 2)){
                mod3 = mod3 * 1.5
            }

            // # 상대도구보정
            if(this.defender && this.defender.equipment == "반감열매"){
                if((this.type_modifier1 * this.type_modifier2) >= 2){
                    if(this.defender && this.defender.ability == "숙성"){
                        mod3 = mod3 * 2
                    }else{
                        mod3 = mod3 * 4
                    }
                }
            }

            return mod3
        },
    },

    mounted(){
        // 포켓몬 목록 불러오기
        this.loadPokemonList()
    },

    methods: {
        // 기술 번역
        translateMove(en) {
            const move = this.move_list.find(item => item.en === en)
            return move ? move.kr : en
        },

        restrictDecimal() {
            if(!this.defender.multiple){
                this.defender.multiple = 1
                return
            }

            let newVal = this.defender.multiple
            const x = newVal.split('.')
            
            if (x.length > 1) {
                const decimal = x[1].slice(0, 2)
                if (decimal === '00') {
                    newVal = x[0]
                } else {
                    newVal = `${x[0]}.${decimal}`
                }
            }
            
            // 입력값이 0.1보다 작은 경우 0.1로 설정
            if (parseFloat(newVal) < 0.1) {
                newVal = '0.1'
            }
            
            // 입력값이 10보다 큰 경우 10으로 설정  
            if (parseFloat(newVal) > 10) {
                newVal = '10'
            }

            this.defender.multiple = newVal
            this.componentKey++
        },

        // 포켓몬 목록 불러오기
        loadPokemonList() {
            this.$http.post("/api/pokemon/select")
            .then((res) => {
                this.pokemon_list = res.data
            })
        },

        defender_types_updated(){
            if(this.defender.types.length > 2){
                this.defender.types.shift()
            }
        },

        removeType(index) {
            this.defender.types.splice(index, 1)
        },

        // 포켓몬(defender) 선택 불러오기
        loadDefender(){
            this.loading = true
            this.$http.get("https://pokeapi.co/api/v2/pokemon/"+this.defender.name)
            .then(async(res) => {
                // 포켓몬 이미지
                this.defender.sprite = res.data.sprites.front_default

                // 포켓몬 타입
                this.defender.types = []
                await res.data.types.forEach(type => {
                    this.$http.get(type.type.url)
                    .then(res => {
                        this.defender.types.push(res.data.names[1].name)
                    })
                })

                // 포켓몬 스탯
                this.defender.stats.hp = res.data.stats[0].base_stat
                this.defender.stats.attack = res.data.stats[1].base_stat
                this.defender.stats.defense = res.data.stats[2].base_stat
                this.defender.stats.special_attack = res.data.stats[3].base_stat
                this.defender.stats.special_defense = res.data.stats[4].base_stat
                this.defender.stats.speed = res.data.stats[5].base_stat

                this.loading = false
            })
        }
    }
}
</script>
<style scoped>
::v-deep .center_label legend {
    text-align: center !important;
    width:40px;
}

::v-deep .center_label .v-text-field .v-label {
    left: 50% !important;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    color: #888888 !important;
    font-size: 9px !important;
    line-height:20px !important;
}

/* v-text-field, v-autocomplete > 입력값 글자 */
::v-deep .center_label .v-text-field input {
    color: #626262 !important;
    font-size:14px !important;
}

::v-deep .center_label .v-text-field .v-label--active {
    transform: translateY(-18px) translateX(-50%);
    padding:0 4px;
    background:white;
}
</style>