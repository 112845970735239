<template>
    <!-- # Attacker -->
    <v-sheet
        :class="!$vuetify.breakpoint.mobile? 'rounded-12':''"
        :style="!$vuetify.breakpoint.mobile?
            {
                width: '362px',
                height: '780px',
                border: '1px solid #ddd',
                boxShadow: '0 0 6px 0 #e1e1e1'
            }
            : 
            {
                height: '100%'
            }
        "
    >
        <!-- # 상단 -->
        <v-sheet
            height="60"
            class="d-flex align-center rounded-t-12"
            style="border-bottom:1px solid #ddd;"
        >
            <!-- # 포켓몬 기본 정보 -->
            <div
                class="flex-grow-1 d-flex align-center"
            >
                <div
                    class="ml-3 mr-3 mb-2px"
                    style="width:36px; height:36px;"
                >
                    <v-img
                        :src="attacker.sprite"
                    ></v-img>
                </div>
                <div>
                    <div
                        class="d-flex"
                        :style="attacker.name && loading == false? 'cursor:pointer;':''"
                        @click="attacker.name && loading == false? $emit('open_stat') : ''"
                    >
                        <p
                            class="mb-0 font-weight-medium alter_black--text mr-6px"
                            style="font-size:12px; letter-spacing:-1px;"
                        >
                            <span v-if="!attacker.name && loading == false">선택값 없음</span>
                            {{attacker.types[0]? attacker.types[0] : (attacker.name? '타입 없음' : '')}} <span v-if="attacker.types[1]">/</span> {{attacker.types[1]}}
                        </p>
                        <v-icon
                            v-if="attacker.name && loading == false"
                            size="14"
                            color="primary lighten-2"
                        >
                            mdi-pencil
                        </v-icon>
                    </div>
                    <p
                        class="mb-0 font-weight-medium alter_grey--text"
                        style="margin-top:-1px; font-size:8px;"
                        :style="attacker.name && loading == false? 'cursor:pointer;':''"
                        @click="attacker.name && loading == false? $emit('open_stat') : ''"
                    >
                        H{{attacker.stats.hp}} A{{attacker.stats.attack}} B{{attacker.stats.defense}} C{{attacker.stats.special_attack}} D{{attacker.stats.special_defense}} S{{attacker.stats.speed}}
                    </p>
                    <p
                        class="mb-0 font-weight-regular alter_grey--text"
                        style="margin-top:-1px; font-size:8px; border-bottom:1px solid #8FC31F;"
                    >
                        시즌 00 싱글 00위 더블 00위
                    </p>
                </div>
            </div>

            <v-spacer></v-spacer>

            <!-- # 교체 -->
            <div
                v-if="$vuetify.breakpoint.mobile"
                style="width:30px; height:16px; padding-top:1px; cursor:pointer;"
                @click="$emit('switch')"
            >
                <v-img
                    src="@/assets/switch.svg"
                ></v-img>
            </div>

            <!-- # 계산 버튼 -->
            <v-btn
                v-if="$vuetify.breakpoint.mobile"
                depressed
                small
                width="64"
                height="26"
                class="rounded-lg ml-2"
                style="background:#A1A4BA; font-size:14px; font-weight:300 !important;"
                dark
                @click="$emit('open_caculate')"
            >
                계산
            </v-btn>

            <!-- # 사용자용 -->
            <div
                v-if="!$store.state.is_admin"
            >
                <!-- 검 -->
                <div
                    class="ml-2 mr-4"
                    style="width:24px; height:24px;"
                    @click="$vuetify.breakpoint.mobile? $store.commit('update_mobile_tab', 'defender'):''"
                >
                    <v-img
                        src="@/assets/attacker.svg"
                    ></v-img>
                </div>
            </div>

            <!-- # 작업용 (스피드) -->
            <div
                v-if="$store.state.is_admin"
                class="d-flex"
            >
                <!-- 검 -->
                <div
                    class="ml-2 mr-3"
                    style="width:24px; height:24px;"
                    @click="$vuetify.breakpoint.mobile? $store.commit('update_mobile_tab', 'defender'):''"
                >
                    <v-img
                        src="@/assets/attacker.svg"
                    ></v-img>
                </div>

                <!-- 스피드 -->
                <div
                    class="mr-4 mt-2px"
                    style="width:14px; height:24px;"
                    @click="$vuetify.breakpoint.mobile? $store.commit('update_mobile_tab', 'speed_attacker') : $store.commit('update_is_speed', false)"
                >
                    <v-img
                        src="@/assets/speed_on.svg"
                    ></v-img>
                </div>
            </div>
        </v-sheet>

        <!-- # 1단 -->
        <v-sheet
            height="80"
            style="border-bottom:1px solid #ddd; padding:10px;"
        >
            <!-- # 1줄 -->
            <div
                class="d-flex align-center"
                style="height:64px;"
            >
                <!-- # 포켓몬 이름 -->
                <v-autocomplete
                    class="rounded-lg shrink"
                    style="width:141px; min-width:141px;"
                    label="포켓몬 이름"
                    placeholder="포켓몬 선택"
                    persistent-placeholder
                    dense
                    hide-details
                    item-value="en"
                    item-text="kr"
                    :items="pokemon_list"
                    v-model="attacker.name"
                    outlined
                    :menu-props="{
                        offsetY: true,
                        maxHeight: $vuetify.breakpoint.mobile ? 216 : 316
                    }"
                    @change="loadAttacker()"
                ></v-autocomplete>

                <!-- # 타입 -->
                <v-select
                    class="ml-1 rounded-lg"
                    label="타입"
                    placeholder="타입 선택"
                    persistent-placeholder
                    dense
                    hide-details
                    :items="type_list"
                    v-model="attacker.types"
                    outlined
                    multiple
                    chips
                    deletable-chips
                    :menu-props="{
                        offsetY: true,
                        maxHeight: $vuetify.breakpoint.mobile ? 216 : 316
                    }"
                    @change="attacker_types_updated()"
                >
                    <template v-slot:selection="{ item, index }">
                        <v-chip
                            small
                            class="mx-1 px-2 pr-3"
                            color="#eee"
                            close
                            @click:close="removeType(index)"
                        >
                            <span>{{ item }}</span>
                        </v-chip>
                    </template>
                </v-select>
            </div>
        </v-sheet>

        <!-- # 2단 -->
        <v-sheet
            height="234"
            style="border-bottom:1px solid #ddd; padding:10px;"
        >
            <!-- # 1줄 -->
            <div
                class="d-flex align-center"
                style="height:28px;"
            >
                <p
                    class="mb-0"
                    style="font-size:14px; font-weight: 500; letter-spacing:-1px;"
                >
                    스피드 수치
                </p>
            </div>

            <!-- # 2줄 -->
            <div
                class="d-flex align-center mt-1 center_label"
                style="height:58px;"
            >
                <!-- # 노력치 -->
                <div
                    class="d-flex align-center"
                >
                    <!-- # 입력 -->
                    <v-text-field
                        class="rounded-lg shrink no-spinners"
                        style="width:50px;"
                        label="노력치"
                        dense
                        hide-details
                        v-model="attacker.effort"
                        outlined
                        type="number"
                        min="0"
                        max="252"
                        placeholder="0"
                        :persistent-placeholder="true"
                    ></v-text-field>

                    <!-- # MAX & MIN -->
                    <div
                        class="ml-5px"
                        style="height:52px;"
                    >
                        <div>
                            <v-btn
                                class="rounded-lg mb-2px"
                                depressed
                                :color="attacker.effort == 252? '#E4007F':'#E9C46A'"
                                dark
                                small
                                height="23"
                                width="54"
                                style="font-size:14px; font-weight:300 !important;"
                                @click="attacker.effort = 252"
                            >
                                MAX
                            </v-btn>
                        </div>
                        <div>
                            <v-btn
                                class="rounded-lg"
                                depressed
                                :color="attacker.effort == 0? '#E4007F':'#E9C46A'"
                                dark
                                small
                                height="23"
                                width="54"
                                style="font-size:14px; font-weight:300 !important;"
                                @click="attacker.effort = 0"
                            >
                                MIN
                            </v-btn>
                        </div>
                    </div>

                    <!-- # + & - -->
                    <div
                        class="ml-1"
                        style="height:52px;"
                    >
                        <div>
                            <v-btn
                                class="rounded-lg mb-2px"
                                depressed
                                color="#E9C46A"
                                dark
                                x-small
                                height="23"
                                width="24"
                                min-width="0"
                                style="font-size:16px; font-weight:300 !important;"
                                @click="attacker.effort < 252? attacker.effort++ : ''"
                            >
                                ＋
                            </v-btn>
                        </div>
                        <div>
                            <v-btn
                                class="rounded-lg"
                                depressed
                                color="#E9C46A"
                                dark
                                x-small
                                height="23"
                                width="24"
                                min-width="0"
                                style="font-size:16px; font-weight:300 !important;"
                                @click="attacker.effort > 0? attacker.effort-- : ''"
                            >
                                －
                            </v-btn>
                        </div>
                    </div>
                </div>

                <!-- # 개체값 -->
                <div
                    class="ml-2 d-flex align-center"
                >
                    <!-- # 입력 -->
                    <v-text-field
                        class="rounded-lg shrink no-spinners"
                        style="width:50px;"
                        label="개체값"
                        dense
                        hide-details
                        v-model="attacker.individual_value"
                        outlined
                        type="number"
                        min="0"
                        max="31"
                        placeholder="0"
                        :persistent-placeholder="true"
                    ></v-text-field>

                    <!-- # MAX & MIN -->
                    <div
                        class="ml-5px"
                        style="height:52px;"
                    >
                        <div>
                            <v-btn
                                class="rounded-lg mb-2px"
                                depressed
                                :color="attacker.individual_value == 31? '#E4007F':'#E9C46A'"
                                dark
                                small
                                height="23"
                                width="54"
                                style="font-size:14px; font-weight:300 !important;"
                                @click="attacker.individual_value = 31"
                            >
                                MAX
                            </v-btn>
                        </div>
                        <div>
                            <v-btn
                                class="rounded-lg"
                                depressed
                                :color="attacker.individual_value == 0? '#E4007F':'#E9C46A'"
                                dark
                                small
                                height="23"
                                width="54"
                                style="font-size:14px; font-weight:300 !important;"
                                @click="attacker.individual_value = 0"
                            >
                                MIN
                            </v-btn>
                        </div>
                    </div>

                    <!-- # + & - -->
                    <div
                        class="ml-1"
                        style="height:52px;"
                    >
                        <div>
                            <v-btn
                                class="rounded-lg mb-2px"
                                depressed
                                color="#E9C46A"
                                dark
                                x-small
                                height="23"
                                width="24"
                                min-width="0"
                                style="font-size:16px; font-weight:300 !important;"
                                @click="attacker.individual_value < 31? attacker.individual_value++ : ''"
                            >
                                ＋
                            </v-btn>
                        </div>
                        <div>
                            <v-btn
                                class="rounded-lg"
                                depressed
                                color="#E9C46A"
                                dark
                                x-small
                                height="23"
                                width="24"
                                min-width="0"
                                style="font-size:16px; font-weight:300 !important;"
                                @click="attacker.individual_value > 0? attacker.individual_value-- : ''"
                            >
                                －
                            </v-btn>
                        </div>
                    </div>
                </div>

                <!-- # 실수값 -->
                <div
                    class="ml-2 d-flex align-center"
                >
                    <!-- # 입력 -->
                    <v-text-field
                        class="rounded-lg shrink no-spinners"
                        style="width:50px;"
                        label="실수값"
                        dense
                        hide-details
                        v-model="speed_modifier"
                        outlined
                        readonly
                    ></v-text-field>
                </div>
            </div>

            <!-- # 3줄 -->
            <div
                class="d-flex align-center mt-1 center_label"
                style="height:58px;"
            >
                <!-- # 랭크업 -->
                <div
                    class="d-flex align-center"
                >
                    <!-- # 입력 -->
                    <v-text-field
                        class="rounded-lg shrink no-spinners"
                        style="width:50px;"
                        label="랭크업"
                        dense
                        hide-details
                        v-model="attacker.rankup"
                        outlined
                        type="number"
                        min="-6"
                        max="6"
                        placeholder="0"
                        :persistent-placeholder="true"
                    ></v-text-field>

                    <!-- # MAX & MIN -->
                    <div
                        class="ml-5px"
                        style="height:52px;"
                    >
                        <div>
                            <v-btn
                                class="rounded-lg mb-2px"
                                depressed
                                :color="attacker.rankup == 6? '#E4007F':'#E9C46A'"
                                dark
                                small
                                height="23"
                                width="54"
                                style="font-size:14px; font-weight:300 !important;"
                                @click="attacker.rankup = 6"
                            >
                                MAX
                            </v-btn>
                        </div>
                        <div>
                            <v-btn
                                class="rounded-lg"
                                depressed
                                :color="attacker.rankup == -6? '#E4007F':'#E9C46A'"
                                dark
                                small
                                height="23"
                                width="54"
                                style="font-size:14px; font-weight:300 !important;"
                                @click="attacker.rankup = -6"
                            >
                                MIN
                            </v-btn>
                        </div>
                    </div>

                    <!-- # + & - -->
                    <div
                        class="ml-1"
                        style="height:52px;"
                    >
                        <div>
                            <v-btn
                                class="rounded-lg mb-2px"
                                depressed
                                color="#E9C46A"
                                dark
                                x-small
                                height="23"
                                width="24"
                                min-width="0"
                                style="font-size:16px; font-weight:300 !important;"
                                @click="attacker.rankup < 6? attacker.rankup++ : ''"
                            >
                                ＋
                            </v-btn>
                        </div>
                        <div>
                            <v-btn
                                class="rounded-lg"
                                depressed
                                color="#E9C46A"
                                dark
                                x-small
                                height="23"
                                width="24"
                                min-width="0"
                                style="font-size:16px; font-weight:300 !important;"
                                @click="attacker.rankup > -6? attacker.rankup-- : ''"
                            >
                                －
                            </v-btn>
                        </div>
                    </div>
                </div>

                <!-- # 레벨 & 결정력 -->
                <div
                    class="ml-2 d-flex align-center"
                >
                    <!-- # 레벨 -->
                    <v-text-field
                        class="rounded-lg shrink no-spinners"
                        style="width:50px; min-width:50px;"
                        label="레벨"
                        dense
                        hide-details
                        v-model="attacker.level"
                        outlined
                        type="number"
                        min="1"
                        max="100"
                        placeholder="0"
                        :persistent-placeholder="true"
                    ></v-text-field>

                    <div
                        class="ml-5px flex-1"
                    >
                        <!-- # 속력 -->
                        <v-text-field
                            class="rounded-lg no-spinners"
                            label="속력"
                            dense
                            hide-details
                            v-model="speed"
                            outlined
                            readonly
                        ></v-text-field>
                    </div>
                </div>
            </div>

            <!-- # 4줄 -->
            <div
                class="d-flex align-center mt-1"
                style="height:58px;"
            >
                <!-- # 성격 보정 -->
                <div
                    class="d-flex align-center center_label"
                    style="position:relative;"
                >
                    <v-text-field
                        class="rounded-lg shrink no-spinners"
                        style="width:195px;"
                        label="성격 보정"
                        placeholder=""
                        persistent-placeholder
                        dense
                        hide-details
                        outlined
                    ></v-text-field>

                    <div
                        style="position:absolute; width:160px; left:50%; transform:translateX(-50%);"
                        class="text_button d-flex justify-space-between align-center"
                    >
                        <v-btn
                            min-width="40"
                            width="40"
                            text
                            small
                            v-ripple="false"
                            style="background:none;"
                            class="font-weight-regular px-0"
                            :color="attacker.personality == 0.9? '#E4007F':''"
                            @click="attacker.personality = 0.9"
                        >
                            <span style="font-size:8px;">x</span>0.9
                        </v-btn>

                        <div style="width:1px; height:14px; background:#969696;"></div>

                        <v-btn
                            min-width="40"
                            width="40"
                            text
                            small
                            v-ripple="false"
                            style="background:none;"
                            class="font-weight-regular px-0"
                            :color="attacker.personality == 1.0? '#E4007F':''"
                            @click="attacker.personality = 1.0"
                        >
                            <span style="font-size:8px;">x</span>1.0
                        </v-btn>

                        <div style="width:1px; height:14px; background:#969696;"></div>

                        <v-btn
                            min-width="40"
                            width="40"
                            text
                            small
                            v-ripple="false"
                            style="background:none;"
                            class="font-weight-regular px-0"
                            :color="attacker.personality == 1.1? '#E4007F':''"
                            @click="attacker.personality = 1.1"
                        >
                            <span style="font-size:8px;">x</span>1.1
                        </v-btn>
                    </div>
                </div>

                <!-- # 배수 & 상태이상 -->
                <div
                    class="ml-5px d-flex align-center"
                >
                    <!-- # 배수 -->
                    <div
                        class="center_label"
                    >
                        <v-text-field
                            class="rounded-lg shrink no-spinners"
                            style="width:60px; min-width:60px;"
                            label="배수"
                            dense
                            hide-details
                            v-model="attacker.multiple"
                            outlined
                            type="number"
                            :min="0.1"
                            :max="10"
                            step="0.01"
                            :precision="2"
                            @change="restrictDecimal"
                            :key="componentKey"
                        ></v-text-field>
                    </div>

                    <!-- # 상태이상 -->
                    <div
                        class="ml-5px flex-1 center_label"
                    >
                        <v-select
                            class="rounded-lg"
                            label="상태이상"
                            placeholder="선택"
                            persistent-placeholder
                            dense
                            hide-details
                            :items="cc_list"
                            v-model="attacker.cc"
                            outlined
                            clearable
                            :menu-props="{
                                offsetY: true,
                                maxHeight: $vuetify.breakpoint.mobile ? 216 : 316
                            }"
                        ></v-select>
                    </div>
                </div>
            </div>
        </v-sheet>

        <!-- # 3단 -->
        <v-sheet
            style="padding:10px;"
        >
            <!-- # 1줄 -->
            <div
                class="d-flex align-center"
                style="height:64px;"
            >
                <!-- # 특성 -->
                <v-autocomplete
                    class="rounded-lg"
                    style="width:195px; min-width:195px;"
                    label="특성"
                    placeholder="특성 선택"
                    persistent-placeholder
                    dense
                    hide-details
                    :items="abilities_list"
                    v-model="attacker.ability"
                    outlined
                    clearable
                    :menu-props="{
                        offsetY: true,
                        maxHeight: $vuetify.breakpoint.mobile ? 152 : 216
                    }"
                ></v-autocomplete>

                <!-- # 날씨 -->
                <div
                    class="ml-5px flex-1"
                >
                    <v-select
                        class="rounded-lg"
                        label="날씨"
                        placeholder="날씨 선택"
                        persistent-placeholder
                        dense
                        hide-details
                        :items="weather_list"
                        v-model="attacker.weather"
                        outlined
                        clearable
                        :menu-props="{
                            offsetY: true,
                            maxHeight: $vuetify.breakpoint.mobile ? 152 : 216
                        }"
                    ></v-select>
                </div>
            </div>

            <!-- # 2줄 -->
            <div
                class="d-flex align-center"
                style="height:64px;"
            >
                <!-- # 도구 -->
                <v-autocomplete
                    class="rounded-lg"
                    style="width:195px; min-width:195px;"
                    label="도구"
                    placeholder="도구 선택"
                    persistent-placeholder
                    dense
                    hide-details
                    :items="equipment_list"
                    v-model="attacker.equipment"
                    outlined
                    clearable
                    :menu-props="{
                        offsetY: true,
                        maxHeight: $vuetify.breakpoint.mobile ? 152 : 216
                    }"
                ></v-autocomplete>

                <!-- # 필드 -->
                <div
                    class="ml-5px flex-1"
                >
                    <v-select
                        class="rounded-lg"
                        label="필드"
                        placeholder="필드 선택"
                        persistent-placeholder
                        dense
                        hide-details
                        :items="field_list"
                        v-model="attacker.field"
                        outlined
                        clearable
                        :menu-props="{
                            offsetY: true,
                            maxHeight: $vuetify.breakpoint.mobile ? 152 : 216
                        }"
                    ></v-select>
                </div>
            </div>

            <!-- # 서포트 -->
            <div
                class="d-flex align-center"
            >
                <v-checkbox
                    class="flex-grow-1"
                    style="width:33%;"
                    label="순풍"
                    dense
                    hide-details
                    v-model="attacker.support.tailwind"
                ></v-checkbox>
                <v-checkbox
                    class="flex-grow-1"
                    style="width:33%;"
                    label="끈적끈적네트"
                    dense
                    hide-details
                    v-model="attacker.support.sticky_web"
                ></v-checkbox>
                <div
                    class="flex-grow-1"
                    style="width:33%;"
                ></div>
            </div>
        </v-sheet>
    </v-sheet>
</template>
<script>
import common from '@/data/common.json'
import speed from '@/data/speed.json'

export default {
    props: [
        "defender",
        "reset_key"
    ],

    data: () => ({
        // 타입 목록
        type_list: common.type_list,

        // 포켓몬 목록
        pokemon_list: [],

        // speed > 기술
        move_list: [],

        // speed > 특성
        abilities_list: speed.abilities_list,

        // speed > 도구
        equipment_list: speed.equipment_list,

        // speed > 상태이상
        cc_list: speed.cc_list,

        // speed > 날씨
        weather_list: speed.weather_list,

        // speed > 필드
        field_list: speed.field_list,
        
        // # Attacker
        attacker: {
            // 이름
            name: "",

            // 이미지
            sprite: null,

            // 타입
            types: [],

            // 스탯
            stats: {
                // H
                hp: 0,

                // A
                attack: 0,

                // B
                defense: 0,

                // C
                special_attack: 0,

                // D
                special_defense: 0,

                // S
                speed: 0,
            },

            // 테라스탈
            terastal: false,

            // 스텔라
            stellar: false,

            // 노력치
            effort: 252,

            // 개체값
            individual_value: 31,

            // 랭크업
            rankup: 0,

            // 공격력 (기술의 damage_class 따라 스탯 attack, special_attack를 대입)
            damage: 0,

            // 성격 보정
            personality: 1.0,

            // 레벨
            level: 50,

            // 배수
            multiple: 1,

            // 특성
            ability: "",

            // 도구
            equipment: "",

            // 상태이상
            cc: "",

            // 날씨
            weather: "",

            // 필드
            field: "",

            // 서포트
            support: {
                // 순풍
                tailwind: false,

                // 끈적끈적네트 
                sticky_web: false,
            }
        },

        loading: true,
        componentKey: 0,
    }),

    watch: {
        reset_key() {
            this.attacker = {
                name: this.attacker.name,
                sprite: this.attacker.sprite,
                types: this.attacker.types,
                stats: this.attacker.stats,
                terastal: false,
                stellar: false,
                effort: 252,
                individual_value: 31,
                rankup: 0,
                damage: 0,
                personality: 1.0,
                level: 50,
                multiple: 1,
                ability: "",
                equipment: "",
                cc: "",
                weather: "",
                field: "",
                support: {
                    tailwind: false,
                    sticky_web: false,
                }
            }
        },

        // attacker 변경시 Emit
        attacker: {
            handler(newValue, oldValue) {
                // 공격력
                // this.attacker.damage = this.attacker.move.damage_class == "physical"? parseInt(this.attacker.stats.attack) : parseInt(this.attacker.stats.special_attack)

                this.$emit('attacker_updated', newValue)
            },
            deep: true
        },
    },

    computed: {
        // # 스피드 실수값
        speed_modifier(){
            // (스피드 종족값(S) + 개체값/2 + 노력치/8 + 5) * 성격보정
            return Math.floor(
                (
                    this.attacker.stats.speed
                    + this.attacker.individual_value / 2
                    + this.attacker.effort / 8
                    + 5
                ) 
                * this.attacker.personality
            )
        },

        // # 속력
        speed(){
            return Math.floor(
                this.speed_modifier
                * this.rankup_value
                * this.ability_mod
                // (보류) * this.opponent_abillity_mod
                * this.equipment_mod
                // (보류) * this.weather_mod
                // (보류) * this.field_mod
                * this.support_mod
                * this.cc_mod
            )
        },

        // # 랭크업 보정
        rankup_value(){
            if(this.attacker.rankup == -6) return 0.25
            else if(this.attacker.rankup == -5) return 0.29
            else if(this.attacker.rankup == -4) return 0.33
            else if(this.attacker.rankup == -3) return 0.4
            else if(this.attacker.rankup == -2) return 0.5
            else if(this.attacker.rankup == -1) return 0.66
            else if(this.attacker.rankup == 0) return 1
            else if(this.attacker.rankup == 1) return 1.5
            else if(this.attacker.rankup == 2) return 2
            else if(this.attacker.rankup == 3) return 2.5
            else if(this.attacker.rankup == 4) return 3
            else if(this.attacker.rankup == 5) return 3.5
            else if(this.attacker.rankup == 6) return 4
        },

        // # 도구 보정
        ability_mod(){
            let ability = 1

            if(this.attacker.ability == "곡예"){
                ability = ability * 2
            }
            else if(
                this.attacker.ability == "고대활성"
                && (
                    this.attacker.equipment == "부스트에너지"
                    ||
                    this.attacker.weather == "쾌청"
                )
            ){
                ability = ability * 1.5
            }
            else if(
                this.attacker.ability == "쿼크차지"
                && (
                    this.attacker.equipment == "부스트에너지"
                    ||
                    this.attacker.field == "일렉트릭필드"
                )
            ){
                ability = ability * 1.5
            }
            else if(this.attacker.ability == "엽록소" && this.attacker.weather == "쾌청"){
                ability = ability * 2
            }
            else if(this.attacker.ability == "쓱쓱" && this.attacker.weather == "비바라기"){
                ability = ability * 2
            }
            else if(this.attacker.ability == "모래헤치기" && this.attacker.weather == "모래바람"){
                ability = ability * 2
            }
            else if(this.attacker.ability == "눈치우기" && this.attacker.weather == "설경"){
                ability = ability * 2
            }
            else if(this.attacker.ability == "서핑테일" && this.attacker.field == "일렉트릭필드"){
                ability = ability * 2
            }
            else if(this.attacker.ability == "속보" && this.attacker.cc != ""){
                ability = ability * 1.5
            }
            else if(this.attacker.ability == "슬로스타트"){
                ability = ability * 0.5
            }

            return ability
        },

        // # 도구 보정
        equipment_mod(){
            let equipment = 1

            if(this.attacker.equipment == "구애스카프"){
                equipment = equipment * 1.5
            }
            else if(this.attacker.equipment == "검은철구"){
                equipment = equipment * 0.5
            }
            else if(this.attacker.equipment == "교정깁스"){
                equipment = equipment * 0.5
            }
            else if(this.attacker.equipment == "파워시리즈"){
                equipment = equipment * 0.5
            }

            return equipment
        },

        // # 서포트 보정
        support_mod(){
            let support = 1

            if(this.attacker.support.tailwind){
                support = support * 2
            }
            if(this.attacker.support.sticky_web){
                support = support * 0.66
            }

            return support
        },

        // # 상태이상 보정
        cc_mod(){
            let cc = 1

            // # 화상
            if(this.attacker.cc == "화상"){
                cc = cc * 0.5
            }

            return cc
        }
    },

    mounted(){
        // 포켓몬 목록 불러오기
        this.loadPokemonList()

        this.loading = false
    },

    methods: {
        // # 포켓몬 목록 불러오기
        loadPokemonList() {
            this.$http.post("/api/pokemon/select")
            .then((res) => {
                this.pokemon_list = res.data
            })
        },

        // # 포켓몬(attacker) 선택 불러오기
        loadAttacker(){
            this.loading = true
            this.$http.get("https://pokeapi.co/api/v2/pokemon/"+this.attacker.name)
            .then(async(res) => {
                // 포켓몬 이미지
                this.attacker.sprite = res.data.sprites.front_default

                // 포켓몬 타입
                this.attacker.types = []
                await res.data.types.forEach(type => {
                    this.$http.get(type.type.url)
                    .then(res => {
                        this.attacker.types.push(res.data.names[1].name)
                    })
                })

                // 포켓몬 스탯
                this.attacker.stats.hp = res.data.stats[0].base_stat
                this.attacker.stats.attack = res.data.stats[1].base_stat
                this.attacker.stats.defense = res.data.stats[2].base_stat
                this.attacker.stats.special_attack = res.data.stats[3].base_stat
                this.attacker.stats.special_defense = res.data.stats[4].base_stat
                this.attacker.stats.speed = res.data.stats[5].base_stat

                // 공격력
                this.attacker.damage = this.attacker.move.damage_class == "physical"? this.attacker.stats.attack : this.attacker.stats.special_attack

                this.loading = false
            })
        },

        // # 포켓몬(attacker) 타입 Shift
        attacker_types_updated(){
            if(this.attacker.types.length > 2){
                this.attacker.types.shift()
            }
        },

        // # 포켓몬(attacker) 타입 remove
        removeType(index) {
            this.attacker.types.splice(index, 1)
        },

        // # 배수
        restrictDecimal() {
            if(!this.attacker.multiple){
                this.attacker.multiple = 1
                return
            }

            let newVal = this.attacker.multiple
            const x = newVal.split('.')
            
            if (x.length > 1) {
                const decimal = x[1].slice(0, 2)
                if (decimal === '00') {
                    newVal = x[0]
                } else {
                    newVal = `${x[0]}.${decimal}`
                }
            }
            
            // 입력값이 0.1보다 작은 경우 0.1로 설정
            if (parseFloat(newVal) < 0.1) {
                newVal = '0.1'
            }
            
            // 입력값이 10보다 큰 경우 10으로 설정  
            if (parseFloat(newVal) > 10) {
                newVal = '10'
            }

            this.attacker.multiple = newVal
            this.componentKey++
        }
    }
}
</script>

<style scoped>
::v-deep .center_label legend {
    text-align: center !important;
    width:40px;
}

::v-deep .center_label .v-text-field .v-label {
    left: 50% !important;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    color: #888888 !important;
    font-size: 9px !important;
    line-height:20px !important;
}

/* v-text-field, v-autocomplete > 입력값 글자 */
::v-deep .center_label .v-text-field input {
    color: #626262 !important;
    font-size:14px !important;
}

::v-deep .center_label .v-text-field .v-label--active {
    transform: translateY(-18px) translateX(-50%);
    padding:0 4px;
    background:white;
}
</style>